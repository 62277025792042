:root {
  /* Color variables */
  --primary-color: #161817;
  --black: #161817;
  --background-color: #693FF3;
  --text-color: #F8F9FA;
  --timer-color: #e74c3c;
  --purple: #693FF3;
  --green: #2CA430;
  --lightgreen: #b1ebb2;
  --red: #FF4747;
  --yellow: #FFDD00;
  /* --white: #f8f9fae7; */
  --white: #F8F9FA;
  --textwhite: #F8F9FA;
  --lightpurple: #D4CEFD;
  --darkpurple: #311980;

  /* Spacing variables */
  --spacing-0: 0px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;

  /* Radius variables */
  --radius-0: 0px;
  --radius-1: 4px;
  --radius-2: 8px;
  --radius-3: 12px;
  --radius-4: 16px;
  --radius-5: 20px;
  --radius-6: 24px;
  --radius-7: 28px;
  --radius-8: 32px;
}

@font-face {
  /* Custom font */
  font-family: 'League-Spartan';
  src: url('/src/styles/LeagueSpartan-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  letter-spacing: -1px;
}

@font-face {
  /* Custom font */
  font-family: 'League-Spartan-Bold';
  src: url('/src/styles/LeagueSpartan-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  letter-spacing: -1px;
}

@font-face {
  /* Custom font */
  font-family: 'Termina';
  src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.eot');
  src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.eot?#iefix') format('embedded-opentype'),
       url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.woff2') format('woff2'),
       url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.woff') format('woff'),
       url(https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Termina-Bold';
    src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.eot');
    src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.eot?#iefix') format('embedded-opentype'),
            url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.woff2') format('woff2'),
         url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.woff') format('woff'),
         url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.ttf') format('truetype'),
         url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  /* Custom font */
  font-family: 'HankenGrotesk-Italic';
  src: url('./styles/HankenGrotesk-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  /* Custom font */
  font-family: 'HankenGrotesk-Regular';
  src: url('./styles/HankenGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  /* Custom font */
  font-family: 'HankenGrotesk-ExtraBold';
  src: url('./styles/HankenGrotesk-ExtraBold.ttf') format('truetype');
}
@font-face {
  /* Custom font */
  font-family: 'HankenGrotesk-SemiBold';
  src: url('./styles/HankenGrotesk-SemiBold.ttf') format('truetype');
}

/* Global styles */
html {
  background-color: var(--purple);
}
body {
  display: block;
  margin: var(--spacing-0);
  /* padding-top: 100px; */
  padding-bottom: 16px;
}
button {
  border-radius: var(--radius-1);
  border: none;
  padding: 12px 16px 12px 16px;
  background: var(--yellow);
  font-family: 'League-Spartan';
  font-size: 24px;
  width: auto;
  cursor: pointer;
  transition: transform 0.3s ease;
}
button.muted-btn {
  border-radius: var(--radius-2);
  border: 1px solid var(--white);
  color: var(--white);
  padding: 20px;
  background: none;
  font-family: 'League-Spartan';
  font-size: 24px;
  width: auto;
  cursor: pointer;
  transition: transform 0.3s ease; 
}
button.green-btn {
  border-radius: var(--radius-2);
  border: 1px solid var(--white);
  padding: 20px;
  background: var(--green);
  color: var(--white);
  font-family: 'League-Spartan';
  font-size: 24px;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;

}
.input-group {
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding-top: 12px;
}
.input-container {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  flex-direction: row;
}
input[type="text"] {
  font-family: 'League-Spartan';
  font-size: 24px;
  padding: 16px;
  border-radius: var(--radius-1);
  border: 1px solid var(--lightpurple);
  width: 100%;
  }
  input[type="email"] {
    font-family: 'League-Spartan';
    font-size: 18px;
    padding: 14px;
    border-radius: var(--radius-2);
    border: 1px solid var(--lightpurple);
    }
    input[type="number"] {
      font-family: 'League-Spartan';
      font-size: 18px;
      padding: 14px;
      border-radius: var(--radius-2);
      border: 1px solid var(--lightpurple);
      }
  a.active {
    margin: 0px;
    color: var(--white);
    border-radius: 12px;
    background: var(--purple);
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    }
  a {
    text-decoration: none;
  }
.sitecontent {
  margin: 32px auto 16px;
  max-width: 900px;
  transition: filter .3s;
}
@media screen and (max-width: 700px){
  .sitecontent {
    margin-top: 0px;
  }
}
.screencontent {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.split-container {
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 700px){
  .split-container {
    gap: 16px;
  }
  
}
.split-column {
  grid-gap: 16px;
  align-items: center;
  border: none;
  border-radius: 16px;
  border-radius: var(--radius-4);
  background: var(--white);
  padding: 32px;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 1000px){
  .split-column {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }
}
.banner-container {
  background: #f8f9fae7;
    background: var(--white);
    border: none;
    border-radius: 8px;
    border-radius: var(--radius-2);
    box-shadow: 0 10px 15px #0000001a;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 20px;
    padding: 32px;
    align-items: flex-start;
}

@media screen and (max-width: 700px) {
  .banner-container {
      gap: 12px;
      padding: 20px;
      width: auto;
      margin: 12px;
  }
}
.app {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: var(--darkpurple);
  padding: 20px;
  transition: transform 0.3s ease;
   height: 100%;
}
.sidebar-container {
  padding-top: 0px;
  padding-bottom: 12px;
  color: var(--white);
}

.sidebar ul {
  list-style-type: none;
  border-top: 1px solid var(--lightpurple);
  border-bottom: 1px solid var(--lightpurple);
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin: 0px;
}

.sidebar ul li {
  margin: 0px;
  color: var(--white);
  padding: 20px;
  border-radius: 12px;
  background: hsl(242deg 46.11% 70.11% / 19%);
   cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar ul li:hover {
  background-color: hsla(242, 46.11%, 70.11%, 0.3);
}

.sidebar ul li.active {
  background-color: hsl(242deg 46.11% 70.11%);
  color: white;
}

.main-content {
  flex-grow: 1;
  background: var(--purple);
  border-radius: 0px;
  flex: 1 1;
  padding: 20px;
  /* transition: margin-left 0.3s ease-in-out; */
  transition: filter 0.3s ease;
}
.single-column {
  display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    gap: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px auto;
  max-width: 700px;
}

.grid-item {
  display: flex;
  justify-content: center;
}

.card {
  width: auto;
  padding: 20px;
  background-color: var(--green);
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}
.mobile-navigation-wrapper {
  display: flex;
  background-color: var(--purple);
  border-bottom: 1px solid var(--white);
  transition: filter 0.3s ease;
}
.mobile-navigation-wrapper.blurred {
  filter: blur(8px);
}


.sidebar .close-icon {
  display: none;
  position: absolute;
  top: 4px;
  right: -32px;
  font-size: 30px;
  cursor: pointer;
  color: var(--white);
}

.sidebar.active .close-icon {
  display: block;
}

@media (min-width: 700px) {
  .sidebar .close-icon {
    display: none !important;
  }
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin: 20px;
  border-radius: 8px;
}
@media screen and (max-width: 700px) {
span.material-icons.hamburger {
  color: var(--white);
  display: block !important;
 }
}

span.material-icons.hamburger {
 display: none;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    z-index: 1;
  }

  .sidebar.active {
    transform: translateX(0);
  }

  .hamburger {
    display: flex;
    background: var(--purple);
    border-radius: 0px;
    padding: 20px;
    margin: 0px;
  }

  .app {
    flex-direction: column;
  }

  .main-content {
    margin-top: 0px;
  }
}
@media screen and (max-width: 700px){
  .main-content.blurred {
    filter: blur(8px);
  }
}
/* .main-content.blurred {
  filter: blur(5px);
} */

.chart-container {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

/* Typography styles */
span {
font-family: 'HankenGrotesk-Regular';
font-size: 18px;
line-height: 1.2;
text-align: center;
}
p {
  font-family: 'League-Spartan';
  font-size: 18px;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  text-align: left;
  color: var(--white);
  letter-spacing: 0px;
}
@media screen and (max-width: 700px){
p {
  font-family: 'League-Spartan';
  font-size: 18px;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  text-align: left;
  color: var(--white);
  letter-spacing: 0px;
}
}
li {
  font-family: 'League-Spartan';
  font-size: 18px;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  text-align: left;
  color: var(--white);
  letter-spacing: 0px;
}
h1 {
font-family: 'League-Spartan';
letter-spacing: -1px;
text-transform: normal;
color: var(--yellow);
font-size: 42px;
line-height: 1;
text-align: center;
padding: 0px;
margin: 0px;
}
@media screen and (max-width: 700px){
h1 {
  font-family: 'League-Spartan';
  color: var(--yellow);
  letter-spacing: -1px;
  text-transform: normal;
  font-size: 37px;
  line-height: 1;
  text-align: left;
  padding: 0px;
  margin: 0px;
}
}
.newsletterpageh1 {
  text-transform: normal;
  font-size: 42px;
  line-height: 1;
  text-align: left;
  padding: 0px;
  margin: 0px;
  }
  h2 {
  font-family: 'League-Spartan';
  letter-spacing: -1px;
  font-size: 38px;
  line-height: 1;
  text-transform: normal;
  text-align: left;
  padding: 0px;
  margin: 0px;
  color: var(--white);
  }
  
  h2.success {
    font-family: 'League-Spartan';
    letter-spacing: -1px;
    font-size: 34px;
    line-height: 1;
    text-transform: normal;
    text-align: center;
    padding: 0px;
    margin: 0px;
    color: var(--black); 
  }
  @media screen and (max-width: 700px){
  h2 {
    font-family: 'League-Spartan';
    letter-spacing: -1px;
    font-size: 32px;
    line-height: 1;
    text-transform: normal;
    text-align: left;
    padding: 0px;
    margin: 0px;
    color: var(--white);
  }
  h2.success {
    font-family: 'League-Spartan';
    letter-spacing: -1px;
    font-size: 34px;
    line-height: 1;
    text-transform: normal;
    text-align: center;
    padding: 0px;
    margin: 0px;
    color: var(--black); 
  }
  }
  h3 {
  font-family: 'League-Spartan';
  letter-spacing: -1px;
  font-size: 38px;
  line-height: 1;
  text-transform: normal;
  text-align: left;
  padding: 0px;
  margin: 0px;
  color: var(--white);
  }
  @media screen and (max-width: 600px){
  h3 {
    font-family: 'League-Spartan';
    letter-spacing: -1px;
    font-size: 28px;
    line-height: 1;
    text-transform: normal;
    text-align: left;
    padding: 0px;
    margin: 0px;
    color: var(--white);
  }
  }
  h4 {
    font-family: 'League-Spartan';
    letter-spacing: -1px;
    font-size: 38px;
    line-height: 1;
    text-transform: normal;
    text-align: left;
    padding: 0px;
    margin: 0px;
    color: var(--white);
    }
.logo {
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.logo-icon {
    width: 60px;
    padding: 0px;
}
.logo-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mindblown {
  text-align: center;
  width: 40%;
  padding-bottom: 10%;
  padding-top: 10%;
  cursor: pointer;
  transition: transform 0.3s ease; 
}
.mindblown-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
img.logo {
  width: 250px;
  display: flex;
  padding: 0px;
 }
  .nav-logo {
      display: none;
  }
@media screen and (max-width: 700px) {
  .nav-logo {
      display: block;
      width: 200px;
      display: flex;
  }
  
}

.logo img {
  width: 250px;
  display: flex;
}
@media screen and (max-width: 700px) {
  .logo img {
      width: 200px;
      display: flex;
  }
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 32px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: block; /* Ensure the icon is displayed as a block element */
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  transition: transform 0.3s ease;
}
/* Footer styles */
.footer-section {
  max-width: 1300px;
  margin: 0 auto;
}

.footer-container {
  background: var(--purple);
  padding: 16px;
  border-radius: var(--radius-2);
  border: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: var(--spacing-4);
  margin: 0 20px;
  color: var(--white)
}
@media screen and (max-width: 700px){
  .footer-container {
    margin: 0px 12px;
  }
}
.footer-links {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .footer-links {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}
.footer-link {
  font-family: 'HankenGrotesk-Regular';
  font-size: 16px;
  line-height: 1.2;
  color: var(--white);
  text-decoration: none;
  transition: transform 0.3s ease;
}
.full-width-input {
  display: flex;
  flex-direction: column;
}
.container {
  padding-top: 0px;
  padding-bottom: 12px;
  color: var(--white);
}
.calculator-container {
  background: var(--purple);
  border-radius: 4px;
  padding: 32px;
  /* box-shadow: 0 10px 15px #0000001a; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px; /* Set a max width */
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px; /* Adjust as needed */
  margin-bottom: 20px;
  position: relative;
  border: 3px solid var(--white);
}
@media screen and (max-width: 700px){
  .calculator-container {
    width: auto;
    margin: 0px;
    border: none;
    padding: 0px;
  }
}
input#hecsDebt {
  width: 100%;
}
input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that you can style it */
  width: 100%; /* Specific width */
  height: 12px; /* Custom thickness */
  background: var(--yellow); /* Track color */
  outline: none; /* Removes outline */
  -webkit-transition: .2s; /* Transition for background changes */
  transition: opacity .2s;
  border-radius: 20px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Hides the thumb so that you can style it */
  appearance: none;
  width: 30px; /* Custom thumb width */
  height: 30px; /* Custom thumb height */
  background: var(--green); /* Thumb color */
  cursor: pointer; /* Cursor on hover */
  border-radius: 20px;
}

input[type="range"]::-moz-range-thumb {
  width: 25px; /* Custom thumb width */
  height: 25px; /* Custom thumb height */
  background: var(--green);
  border-radius: 20px;;
  cursor: pointer; /* Cursor on hover */
}
.full-width-container {
  background: var(--white);
  border: none;
  border-radius: 16px;
  border-radius: var(--radius-4);
  margin: auto;
  padding: 32px;
  box-shadow: 0 10px 15px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (max-width: 700px){
  .full-width-container {
    padding: 20px;
  }
}
.purple-text {
  color: var(--purple);
  font-family: inherit;
  font-size: inherit;
}
.yellow-text {
  color: var(--yellow);
  font-family: inherit;
  font-size: inherit;
}
